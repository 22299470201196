<template>
  <div class="aali_tm_section" id="contact">
    <div class="aali_tm_contact">
      <div class="container">
        <div
          class="aali_tm_main_title"
          data-text-align="center"
          data-color="dark"
        >
          <span>Get in Touch</span>
          <h3>Connect with Me with Confidence</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div class="text">
              <p>
                Please fill out the form on this section to contact with me. Or
                call between 9:00 a.m. and 8:00 p.m. UTC+7, Monday through Friday
              </p>
            </div>
            <ul>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    `<span>Call me</span>
                  <h3>+6287 8374 40698</h3>` + call
                  "
                ></div>
              </li>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    msg +
                    `<span>Email</span>
                  <h3>
                    <a class='line_anim' href='mailto:hello@hereabran.space'
                      >hello@hereabran.space</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form
                class="contact_form"
                id="contact_form"
                @submit.prevent="handleSubmit"
              >
                <div
                  class="returnmessage"
                  data-success="Your message has been received, We will contact you soon."
                  :style="{
                    display: success ? 'block' : 'none',
                  }"
                >
                  <span class="contact_success">
                    Your message has been received, We will contact you soon.
                  </span>
                </div>
                <div
                  class="empty_notice"
                  :style="{ display: error ? 'block' : 'none' }"
                >
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="input_list">
                  <ul>
                    <li>
                      <input
                        id="name"
                        type="text"
                        v-model="fromData.name"
                        placeholder="Your Name"
                      />
                    </li>
                    <li>
                      <input
                        id="email"
                        type="text"
                        v-model="fromData.email"
                        placeholder="Your Email"
                      />
                    </li>
                    <li>
                      <input
                        id="contry_city"
                        type="text"
                        v-model="fromData.country_city"
                        placeholder="Your Country/City"
                      />
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <textarea
                    id="message"
                    placeholder="Your message here"
                    v-model="fromData.msg"
                  ></textarea>
                </div>
                <div class="aali_tm_button">
                  <div><strong style="color:red">{{ fromData.pleaseTickRecaptchaMessage }}</strong></div>
                  <vue-recaptcha 
                    style="margin-bottom:1em" 
                    @verify="markRecaptchaAsVerified"
                    :sitekey="sitekey">
                  </vue-recaptcha>
                  <a id="send_message" href="#" @click.prevent="handleSubmit">
                    <span v-html="'Submit Message' + msgSent"> </span>
                  </a>
                </div>

                <!-- If you want change mail address to yours, just open "modal" folder >> contact.php and go to line 4 and change detail to yours.  -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <span
        class="square moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script src="https://unpkg.com/vue-demi@0.12.1/lib/index.iife.js"></script>
<script src="https://unpkg.com/vue-recaptcha@^2/dist/vue-recaptcha.min.js"></script>

<script>
import { call, msg, msgSent } from "./svgImage";
import emailjs from 'emailjs-com';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: "Contact",
  components: {
    'vue-recaptcha': VueRecaptcha,
  },
  data() {
    return {
      sitekey: "6LfWTJ8fAAAAAEEZka6Z_8Si_nkqX9xaJdska4Nl",
      // sitekey: "6LdPMJ8fAAAAAKvcelNoV5w4Kmq_qz3eBpS6UxcI",
      msgSent,
      call,
      msg,
      fromData: {
        name: "",
        email: "",
        country_city: "",
        msg: "",
        recaptchaVerified: false,
        pleaseTickRecaptchaMessage: '',
      },
      error: false,
      success: false,
    };
  },
  methods: {
    handleSubmit() {
      const { name, email, country_city, msg } = this.fromData;

      if (!this.fromData.recaptchaVerified) {
        this.fromData.pleaseTickRecaptchaMessage = 'Please tick the reRECAPTCHA.';
        return true; // prevent form from submitting
      }

      if (name && email && country_city && msg) {
        this.success = true;

        setTimeout(() => {
          this.success = false;
        }, 20000);

        emailjs.send("service_4rdlgtt","test_template",{
          "from_name": name,
          "from_email": email,
          "from_country_city": country_city,
          "message": msg,
        }, "user_CZqUHwJBXUVCOqv1am5SR")
        .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
          console.log('FAILED...', error);
        });

      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },
    markRecaptchaAsVerified(response) {
      this.fromData.pleaseTickRecaptchaMessage = '';
      this.fromData.recaptchaVerified = true;
    },
  },
};
</script>
